import getConfig from 'next/config';
import { addMinutes } from 'date-fns';

import * as COOKIES from '../constants/Cookies';
import * as casHelpers from '../server/logging';

const {
  CAS_ID_COOKIE, CIVIC_COOKIE_CONTROL, CIVIC_FUNCTIONAL_COOKIE, FUNCTIONAL_COOKIES, COOKIE_TYPES,
} = COOKIES;

const { COOKIE_DOMAIN, UserSessionExpirationMinutes } = getConfig().publicRuntimeConfig.ENVIRONMENT;

export function getCookie(name) {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
}

export function getCasID() {
  const casURL = getCookie(CAS_ID_COOKIE);
  return casHelpers.getCasIDFromURL(casURL);
}

export function acceptedCivicConsent(cookieName, type = COOKIE_TYPES.FUNCTIONAL) {
  const hasCivicCookieControl = !!getCookie(CIVIC_COOKIE_CONTROL);

  // Allow any cookie assignment if civic is not installed
  if (!hasCivicCookieControl) {
    return true;
  }

  if (type === COOKIE_TYPES.FUNCTIONAL && FUNCTIONAL_COOKIES.includes(cookieName)) {
    return !!getCookie(CIVIC_FUNCTIONAL_COOKIE);
  }
  return true;
}

export function setCookie(options, value, callback) {
  if (!options.name) return '';

  const { name, ...otherOptions } = options;

  if (!acceptedCivicConsent(name)) {
    return '';
  }

  let cookie = `${name}=${value};`;
  cookie += Object.keys(otherOptions).filter(key => !!key).map(key => `${key}=${otherOptions[key]}`).join(';');

  document.cookie = cookie;

  return callback && callback(cookie);
}

export function setDomainCookie(options, value, callback) {
  const mergedOptions = {
    name: '',
    domain: COOKIE_DOMAIN,
    expires: addMinutes(new Date(), UserSessionExpirationMinutes),
    ...options,
  };

  if (!mergedOptions.name) return '';

  return setCookie(mergedOptions, value, callback);
}

export function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export default COOKIES;
