const CACHE_EXPIRY_KEY = 'CACHE_EXPIRY';
const SCHEMA_KEY = 'CAMC-SCHEMA';
const SCHEMA_VERSION_KEY = 'schema_version';
const CIVIC_COOKIE_KEY = 'CookieControl';

module.exports = {
  CACHE_EXPIRY_KEY,
  SCHEMA_KEY,
  SCHEMA_VERSION_KEY,
  CIVIC_COOKIE_KEY,
};
