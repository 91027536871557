export const CIVIC_COOKIE_CONTROL = 'CookieControl';
export const CIVIC_FUNCTIONAL_COOKIE = 'CivicConsentFunctional';

export const CAS_ID_COOKIE = 'cas';

export const HELP_BUBBLE_COOKIE = 'HelpBubble';
export const HIDE_HELP_BUBBLE_COOKIE = 'hideHelpBubble';
export const WEBVIEW_KEY = 'webview';
// Value set by mobile app for app view (no header & footer)
// Also used to exclude GTM in the app
// Can be used ond desktop with &webview=dev
export const WEBVIEW_VALUE = 'dev';

export const COOKIE_TYPES = {
  FUNCTIONAL: 'functional',
};

export const FUNCTIONAL_COOKIES = [
  HELP_BUBBLE_COOKIE,
  HIDE_HELP_BUBBLE_COOKIE,
];
