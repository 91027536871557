import Router from 'next/router';

import { checkWebview, postMessage } from './mobileApp';
import logToCasClientSide, { AuditType } from '../logToCasClientSide';

// Use for bespoke handling per type and sending through types to WebView
export const POST_TYPES = {
  NEW_WINDOW: 'New Window',
  LOGIN: 'Login',
  PAYMENT: 'Payment',
  MEMBERSHIP_RENEWAL: 'MembershipRenewal',
  MEMBERSHIP_JOIN: 'MembershipJoin',
  FILE: 'File',
};

/**
 * Designed to be utilized as the sole method for route navigation and redirects,
 * this is so we can direct navigation to the native app if we are in a webView.
 *
 * @param {string} url
 * @param {object} options { type: POST_TYPES, query: Object }}
 */
export const navigateTo = (url, options = {}) => {
  if (!url) return;

  // types listed in here will not send a message to viewView, but continue
  // using default navigation as used within browser.
  const blackListedTypesForWebView = [
    POST_TYPES.PAYMENT,
  ];

  const isWebView = checkWebview();
  const isRelative = !url.includes('http');
  const isSameOrigin = url.includes(window.location.origin);

  // Log any navigation events to CAS
  logToCasClientSide(AuditType.Information, 'Navigation.navigateTo', 'Redirect', {
    url,
    isWebView,
    options,
  });

  // send navigation message through to WebView
  if (isWebView && !isRelative && !blackListedTypesForWebView.includes(options.type)) {
    const type = options.type || POST_TYPES.NEW_WINDOW;
    postMessage(type, url); // WebView redirect
    return;
  }

  // browser open new window
  if (
    options.type === POST_TYPES.NEW_WINDOW
    || options.type === POST_TYPES.FILE
    || options.type === POST_TYPES.MEMBERSHIP_RENEWAL
    || options.type === POST_TYPES.MEMBERSHIP_JOIN
  ) {
    window.open(url, '_blank');
    return;
  }

  // External url, such as local payment broker
  if (!isSameOrigin && !isRelative) {
    window.location.href = url;
    return;
  }

  // Absolute internal urls utilise existing functionality to simply force a navigation
  if (isSameOrigin) {
    window.location.href = url;
    return;
  }

  // Relative urls make use of router to navigate
  if (isRelative) {
    // ensure existing stack has completed, because subsequent query
    // param updates via Router will override this navigation request
    setTimeout(() => {
      Router.push(url, { query: { ...options?.query } });
    }, 0);
    return;
  }

  throw Error(`Error: navigateTo, not able to handle url ${url}`);
};
