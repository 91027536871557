import COOKIES, { setCookie, getCookie, deleteCookie } from '../cookies';

export const checkWebview = (query = undefined) => {
  const webviewCookie = getCookie(COOKIES.WEBVIEW_KEY);
  const webviewStore = localStorage.webview; // backup check if cookie fails
  const webviewQuery = query?.[COOKIES.WEBVIEW_KEY];
  const currentWebview = webviewQuery || webviewCookie || webviewStore;
  const isWebviewEnabled = !!currentWebview;
  // TODO: remove test if env is setup
  if (isWebviewEnabled && currentWebview === COOKIES.WEBVIEW_VALUE) {
    if (!webviewCookie) {
      // max date of cookie is 2038 http://www.faqs.org/rfcs/rfc2965.html
      const expires = new Date(2038, 0, 19).toUTCString();
      // Remember webview
      setCookie({ name: COOKIES.WEBVIEW_KEY, expires }, currentWebview);
    }
    if (!webviewStore) {
      localStorage.setItem('webview', currentWebview);
    }
    return true;
  }
  // Reset webview key if saved key is invalid
  if (webviewCookie) {
    deleteCookie(COOKIES.WEBVIEW_KEY);
  }
  if (webviewStore) {
    localStorage.removeItem('webview');
  }
  return false;
};

export default checkWebview;

export const postMessage = (postType, message) => {
  try {
    const messageData = JSON.stringify({ type: postType, body: message });
    console.info('Post message to App:', messageData);
    window.ReactNativeWebView.postMessage(messageData);
  } catch (error) {
    console.error('Error posting message to App:', JSON.stringify(error));
  }
};
