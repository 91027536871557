import groupBy from 'lodash/groupBy';
import { sortObjectsByKey, sortObjectsByTwoKeys } from './sortArrayOfObjects';
import { defaultFilterableTypes } from '../config/campsiteTypes';

export const CAMPSITE_SORT_TYPES = {
  SITE_NAME: 0,
  PRICE_DSC: 1,
  PRICE_ASC: 2,
  AVERAGE_RATING: 3,
  DISTANCE_ASC: 4,
  CLUB_SITES_FIRST: 5,
};

export const CAMPSITE_SORT_LABELS = {
  [CAMPSITE_SORT_TYPES.SITE_NAME]: 'SearchResultsHeader__SortBySiteName__Text',
  [CAMPSITE_SORT_TYPES.PRICE_DSC]: 'SearchResultsHeader__SortByPriceDescending__Text',
  [CAMPSITE_SORT_TYPES.PRICE_ASC]: 'SearchResultsHeader__SortByPriceAscending__Text',
  [CAMPSITE_SORT_TYPES.AVERAGE_RATING]: 'SearchResultsHeader__SortByAverageRating__Text',
  [CAMPSITE_SORT_TYPES.DISTANCE_ASC]: 'SearchResultsHeader__SortByDistanceAscending__Text',
  [CAMPSITE_SORT_TYPES.CLUB_SITES_FIRST]: 'SearchResultsHeader__SortByClubSitesFirst__Text',
};

export const sortCampsites = (campsites = [], sortType, queryTypes = []) => {
  if (sortType === CAMPSITE_SORT_TYPES.DISTANCE_ASC || sortType === CAMPSITE_SORT_TYPES.SITE_NAME) {
    return campsites;
  }

  const allQueryTypes = queryTypes?.length ? queryTypes : defaultFilterableTypes;
  const groupedCampsites = groupBy(campsites, campsite => campsite.type);
  const sortedGroupedCampsites = allQueryTypes
    .filter(queryType => Object.keys(groupedCampsites).includes(String(queryType)))
    .map((queryType) => {
      if (
        sortType === CAMPSITE_SORT_TYPES.PRICE_ASC
        || sortType === CAMPSITE_SORT_TYPES.PRICE_DSC
      ) {
        return sortObjectsByKey(
          groupedCampsites[queryType],
          'memberPrice',
          sortType === CAMPSITE_SORT_TYPES.PRICE_DSC,
          'isFeatured',
        );
      }
      if (sortType === CAMPSITE_SORT_TYPES.AVERAGE_RATING) {
        return sortObjectsByTwoKeys(
          groupedCampsites[queryType],
          'rating',
          'numberOfReviews',
          'isFeatured',
        );
      }
      return groupedCampsites[queryType];
    });
  return sortedGroupedCampsites.flat();
};

export const closeDetailsQuery = (currentQuery = {}) => {
  const query = { ...currentQuery };

  query.campsiteId = '';
  query.campsiteName = '';
  query.siteCode = '';
  query.tourCode = '';
  query.reviews = '';
  query.componentId = '';

  return query;
};
