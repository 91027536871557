import gql from 'graphql-tag';

export default gql`
  fragment Itinerary on Itinerary {
    accommodation {
      description
      isAdultApplicable
      isChildApplicable
      isInfantApplicable
      isMandatory
      isOffer
      isPerPerson
      maxOccupancy
      maxQuantity
      name
      notes
      partyMemberReference
      priceBreakdown
      quantity
      totalPrice
      type
      uId
    }
    arrivalPort
    crossingDateTime
    departurePort
    errata
    supplements {
      comments
      description
      end
      id
      isMandatory
      isOffer
      isPerPerson
      name
      notes
      parentId
      partyMemberReference
      priceBreakdown
      quantity
      start
      totalPrice
      type
      uId
    }
    timeTable {
      fromDate
      toDate
    }
    transportName
  }

  fragment CrossingBooking on CrossingBookings {
    basePrice
    changeMessage
    changePrice
    changeType
    depositAmount
    fareType
    id
    inboundItinerary @type(name: "Itinerary") {
      ...Itinerary
    }
    outboundItinerary @type(name: "Itinerary") {
      ...Itinerary
    }
    relatedFareTypeId
    supplierCode
    totalPrice
  }

  fragment Extra on Extra {
    changePrice
    changeType
    changeUserNotified
    comments
    depositAmount
    description
    end
    id
    isAdultApplicable
    isChildApplicable
    isInfantApplicable
    isMandatory
    name
    notes
    parentId
    partyMemberReference
    priceBreakdown
    quantity
    start
    totalPrice
    type
    uId
  }

  fragment PartyMemberFrag on PartyMember {
    comment
    dateOfBirth
    gender
    age
    isLead
    personId
    personName {
      firstName
      prefix
      surname
    }
    specialRequests
    partyMemberStayType
    telephones
    type
  }

  fragment SpecialRequest on SpecialRequest {
    commentId
    id
    name
  }

  fragment Supplement on Supplement {
    changePrice
    changeType
    changeUserNotified
    comments
    depositAmount
    description
    end
    id
    isAdultApplicable
    isChildApplicable
    isInfantApplicable
    isMandatory
    isOffer
    isPerPerson
    name
    notes
    parentId
    partyMemberReference
    priceBreakdown
    quantity
    start
    totalPrice
    type
    uId
  }

  fragment Pitch on Pitch {
    actualDatesBooked
    advancedGuests
    arrivalDateTime
    arrivalTime
    basePrice
    bookingDates {
      fromDate
      toDate
    }
    cancelReasonId
    cancellationFee
    code
    depositAmount
    errata
    eventType
    guests
    id
    isAdvancedGuestPitch
    isMemberExclusive
    isNonRefundable
    numNights
    outfit
    parentPitchId
    partyMemberReference
    preventBookingComponents
    specialRequests @type(name: "SpecialRequest") {
      ...SpecialRequest
    }
    supplements @type(name: "Supplement") {
      ...Supplement
    }
    totalPrice
    type
    warnBookingComponents
  }
`;
