import gql from 'graphql-tag';

export default gql`
  mutation updateCurrentCampsite(
    $id: String,
    $name: String,
    $lat: Float,
    $lng: Float,
    $zoomLevel: Float,
    $zoomTo: Boolean
  ) {
    updateCurrentCampsite(
      id: $id,
      name: $name,
      lat: $lat,
      lng: $lng,
      zoomLevel: $zoomLevel,
      zoomTo: $zoomTo
    ) @client
  }
`;
