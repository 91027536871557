export default (arrayfOfObjects, sortyBy) => arrayfOfObjects.sort((a, b) => {
  if (a[sortyBy] < b[sortyBy]) {
    return -1;
  }
  if (a[sortyBy] > b[sortyBy]) {
    return 1;
  }
  return 0;
});

// Sort objects by object key, but always placing items with 0 or no value at the bottom
export function sortObjectsByKey(objects = [], key = '', reverse = false, priorityKey) {
  return objects.sort((a, b) => {
    // values of object with truish priorityKey will stay on top
    if (priorityKey) {
      if (a[priorityKey]) {
        return -1;
      }
      if (b[priorityKey]) {
        return 1;
      }
    }
    const value1 = a[key] ?? 0;
    const value2 = b[key] ?? 0;
    const sortFactor = reverse ? 1 : -1;
    if (!value1 && !value2) return 0;
    if (reverse ? !value1 : !value2) return sortFactor;
    if (reverse ? !value2 : !value1) return -sortFactor;
    return reverse ? value2 - value1 : value1 - value2;
  });
}

// Sort objects using two values, the first one as priority
export function sortObjectsByTwoKeys(objects, key1, key2, priorityKey) {
  return objects.sort((a, b) => {
    if (priorityKey) {
      if (a[priorityKey]) {
        return -1;
      }
      if (b[priorityKey]) {
        return 1;
      }
    }
    return b[key1] - a[key1] || b[key2] - a[key2];
  });
}
