import { merge } from 'lodash';

import { GET_CURRENT_CAMPSITE } from '../config/graphql';

export default {
  defaults: {
    currentCampsite: {
      __typename: 'CurrentCampsite',
      id: null,
      name: null,
      lat: null,
      lng: null,
      zoomLevel: null,
      zoomTo: false,
    },
  },
  resolvers: {
    Mutation: {
      updateCurrentCampsite: (_, currentCampsite, { cache }) => {
        const previous = cache.readQuery({ query: GET_CURRENT_CAMPSITE });
        const data = {
          currentCampsite: merge(previous.currentCampsite, currentCampsite),
        };
        cache.writeData({ data });
        return data;
      },
    },
  },
};
