const resolver = {
  defaults: {
    campsite: {
      __typename: 'Campsite',
      location: {
        __typename: 'Location',
        lat: '',
        lon: '',
      },
      pitches: [],
    },
  },
};

export default resolver;

export const { defaults } = resolver;
