import gql from 'graphql-tag';

import quoteFragments from './quoteFragments';

export default gql`
  ${quoteFragments}

  query Quote {
    quote @rest(
      type: "Quote"
      path: "Reservations/ViewQuote"
      method: "GET"
    ) {
      balanceDueDate
      basketState
      bookingAmendmentBalanceDue
      bookingAmendmentMaxBalanceDue
      basketHoldExpiryTimestamp
      basketHoldMaxTimestamp
      basketHoldStartTimestamp
      bookingReference
      cancellationPenalty
      cancellationPenaltyApplicableFrom
      campsiteBookings {
        id
        campsite {
          id
          name
          address {
            country
            county
            addressLines
            postCode
          }
          siteCode
          type
        }
        pitches {
          ...Pitch
        }
      }
      crossingBookings @type(name: "CrossingBookings") {
        ...CrossingBooking
      }
      deposit
      departureDate
      defaultAdultDobDate
      errorCode
      extras {
        ...Extra
      }
      isBookingAmendmentQuote
      isMemberQuote
      membership
      membershipType
      nonMemberSupplementsCost
      outfit {
        externalId
        id
        towHeight
        towLength
        towType
        towMake
        towModel
        vehicleHeight
        vehicleLength
        vehicleMake
        vehicleModel
        vehicleReg
        vehicleType
      }
      partyMembers {
        ...PartyMemberFrag
      }
      phoneNumber {
        countryPrefix
        number
      }
      product
      quoteId
      status
      totalPrice
      totalPriceIncludesNonMemberSupplements
    }
}
`;
