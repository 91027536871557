export default `
  input AvailabilityConfigInput {
    dictionaryKeys: [String]!
  }

  type AddComponentResult {
    index: Int
    id: String
    type: Int
    successful: Bool
    errorCode: Int
    errorMessage: String
  }

  type Availability {
    errorCode: Int!
    pitchTypes: [AvailabilityPitchType]
  }

  type AvailabilityDetails {
    extras: [Extra]
    errata: [String]
    supplements: [Supplement]
  }

  type AvailabilityPitchType {
    code: String
    maxNights: Int
    minNights: Int
    minPricePerNight: Float
    availability: [Float]
  }

  type Address {
    addressLines: [String]
    country: String
    county: String
    postCode: String
  }

  type OpenDate {
    fromDate: String
    toDate: String
  }

  type CampsiteListing {
    count: Float
    data: [Campsite]
  }

  type Payment {
    Amount: Int
    CardType: String
  }

  input BrowserDetailsInput {
    IpAddress: String
    ScreenHeight: Int
    ScreenWidth: Int
  }

  input PaymentInput {
    Amount: Int!
    CardType: String!
    BrowserDetails: BrowserDetailsInput
  }

  type Event {
    cheapestMemberPrice: Int
    cheapestStandardPrice: Int
    earliestArrival: String
    eventType: Int
    features: [Feature]
    id: String
    latestArrival: String
    maxOutfitLength: Int
    openDates: [OpenDate]
  }

  type Campsite {
    id: String
    address {
      addressLines: String
      country: String
      postCode: String
    }
    events: [Event]
    features: [Feature]
    isMemberOnly: Boolean
    isoPlaceCode: String
    location {
      lat: Float
      lon: Float
    }
    name: String
    numberOfReviews: Int
    pitches {
      id: String
      description: String
      isMemberExclusive: Boolean
      maxChildAge: Number
      maxOccupancy: Number
      maxOutfitLength: Number
      minChildAge: Number
      name: String
    }
    rating: Int
    siteCode: String
    type Int
  }

  type Configuration {
    availabilityMaxDate: String
    campsiteTypes: [KeyValue]!
    defaultCampsiteLogoImage: String
    defaultCampsiteZoomLevel: Int
    defaultMaxChildAge: Int
    defaultMaxInfantAge: Int
    defaultMaxPets: Int
    campaignCodes: [CampaignCode]
    errorCodeTypes: [KeyValue]!
    features: [Feature]!
    internationalPhoneAccessCodes: [Int]!
    partyMemberTypes: [KeyValue]!
    pitchTypesLink: String
    routes: [Route]
    nearbyFilterableFeatures: [String]!
    onSiteFilterableFeatures: [String]!
    siteNightVoucherInfo: SiteNightVoucherInfo
    termsAndConditionsLink: String!
    telephoneTypes: [OutfitType]
    topTenFeatures: [String]!
    towTypes: [OutfitType]!
    vehicleTypes: [OutfitType]!
  }

  type Routes {
    arrivalPort: String
    arrivalPortLocation: [Location]
    arrivalPortName: String
    departurePort: String
    departurePortLocation: [Location]
    departurePortName: String
    description: String
    id: String
    isOutbound: Boolean
    location: [Location]
    returnRouteCode: String
    returnRouteCodeWeb: String
    routeCode: String!
    routeCodeWeb: String
    routeLine: [Location]
    supplierCode: String
    zoomLevel: String
  }

  type Location {
    lat: Float
    lon: Float
  }

  type PartyConfig {
    defaultValue: Int
    fromAge: Int
    min: Int
    type: Int
  }

  type CampaignCode {
    campaignCode: String
    siteId: String
    zoomLevel: Int
    lat: Int
    lon: Int
    searchDates: BookingDates
  }

  type BookingDates {
    fromDate: String
    toDate: String
  }

  type SearchCrossings {
    Crossings: [Crossing]
  }

  type GroupedCrossings {
    groupedCrossings: [GroupedCrossing]
  }

  type GroupedCrossing {
    crossings: [Crossing]
    id: String
  }

  type Crossing {
    outboundItinerary: Itinerary
    inboundItinerary: Itinerary
    fareType: String
    id: String
    partyMembers: PartyMembersInput
    supplierCode: String
    charges: Charge
  }

  input LocatorInput {
    id: ID!
    quantity: Int!
  }

  input CrossingSearchItineraryInput {
    id: ID!
    accommodation: [LocatorInput]
    supplements: [LocatorInput]
  }

  input SearchCrossingsInput {
    outfit: OutfitInput
    partyMembers: PartyMembersInput
    selectedItinerary: CrossingSearchItineraryInput
  }

  input SearchCrossingsInput {
    Outfit: OutfitInput
    InboundItinerary: ItineraryInput
    OutboundItinerary: ItineraryInput
  }

  type Itinerary {
    timeTable: BookingDates
    transportName: String
    departurePort: String
    arrivalPort: String
    crossingDateTime: String
  }

  input ItineraryInput {
    CrossingDateTime: String
    CrossingRouteCode: String
  }

  type Passenger {
    Age: Int
    Code: Int
    Quantity: Int
  }

  input CreateQuoteInput {
    start: String
    end: String
    partyMembers: [PartyMemberInput]
  }

  type CurrentCampsite {
    id: String
    name: String
    lat: Float
    lng: Float
    zoomLevel: Float
    zoomTo: Boolean
  }

  type CompareModuleState {
    active: Bool!
  }

  type ConfirmationModalState {
    active: Bool!
  }

  type ComparisonCampsite {
    id: String
    address {
      addressLines: String
      country: String
      postCode: String
    }
    features: [Feature]
    isMemberOnly: Boolean
    isoPlaceCode: String
    location {
      lat: Float
      lon: Float
    }
    name: String
    numberOfReviews: Int
    pitches {
      id: String
      description: String
      isMemberExclusive: Boolean
      maxChildAge: Number
      maxOccupancy: Number
      maxOutfitLength: Number
      minChildAge: Number
      name: String
    }
    rating: Int
    siteCode: String
    type: Int
    arrive: String
    depart: String
  }

  type Extra {
    comments: String
    description: String
    end: String
    id: ID!
    isAdultApplical: Boolean
    isChildApplicable: Boolean
    isInfantApplicable: Boolean
    isMandatory: Boolean
    name: String!
    errata: [String]
    partyMemberReference: [String]
    quantity: Int!
    start: String
    totalPrice: Float!
    type: String
  }

  input ExtraInput {
    id: ID!
    quantity: Int!
  }

  type Extras {
    errorCode: Int
    extras: [Extra]
  }

  type Feature {
    id: ID
    name: String
    value: STring
    tags: [Int]
  }

  type DictionaryItem {
    key: String!
    value: String!
  }

  type KeyValue {
    key: Int!
    value: String!
  }

  input OutfitInput {
    externalId: String
    towType: String
    towHeight: Int
    towLength: Int
    vehicleType: String!
    vehicleHeight: Int!
    vehicleLength: Int!
  }

  type OutfitType {
    key: Int!
    maxHeight: Int
    maxLength: Int
    minHeight: Int
    minLength: Int
    value: String!
  }

  type PartyMemberInput {
    age: Int
    personId: ID!
    type: Int!
    personName: PersonName
  }

  type PersonName {
    prefix: String!
    firstName: String!
    surname: String!
  }

  type Price {
    id: String
    memberPrice: Float
    offers: [String]
    price: Float
    standardPrice: Float
  }

  type Quote {
    cancellationPenaltyApplicableFrom: String
    campsiteBookings: [CampsiteBooking]
    crossingBookings: [CrossingBooking]
    deposit: Float!
    errataAccepted: Bool
    errorCode: Int
    extras: [Extra]!
    quoteId: ID!
    outfit: Outfit!
    partyMembers: [PartyMember]!
    promotionalCode: String
    specialRequests: [SpecialRequest]
    totalPrice: Float!
    phoneNumber: PhoneNumber
  }

  type QuoteResponse {
    quote: Quote!
  }

  type UpdateQuoteMembershipDetails {
    errorCode: Int
  }

  type UpdateQuoteMembershipDetailsInput {
    test: String
  }

  type UpdateQuoteAdditional {
    partyMembers: [PartyMember]!
    outfit: Outfit!
    phoneNumber: PhoneNumber!
  }

  input UpdateQuoteAdditionalInput {
    partyMembers: [PartyMember]!
    outfit: Outfit!
    phoneNumber: PhoneNumber!
  }

  input UpdateQuoteAmendAdditionalInput {
    partyMembers: [PartyMember]!
  }

  input QuoteUpdateExtrasInput {
    extras: [ExtraInput]!
  }

  type CampsiteBooking {
    campsite: {
      siteCode: String
    }
    id: ID
    campsiteId: ID
    pitches: [Pitch]
  }

  type CrossingBooking {
    id: ID
  }

  type Pitch {
    code: ID
    end: String
    id: ID
    isNonRefundable: Bool
    maxOccupancy: Int
    maxOutfitLength: Int
    name: String
    numNights: Int
    start: String
    supplements: [Supplement]
    totalPrice: Float
    type: String
    eventType: Int
    isMemberExclusive: Bool
  }

  input QuoteInput {
    start: String!
    end: String!
    siteCode: String!
    pitchTypeId: String!
    promotionalCode: String
    errataAccepted: Bool!
    partyMembers: [PartyMemberInput!]!
    outfit: Outfit!
    extras: [Extra]
    specialRequests: [SpecialRequest]
  }

  input AmendBookingOutfit {
    arrivalTime: Int
    quoteId: String
    start: String!
    end: String!
    siteCode: String!
    pitchTypeId: String!
    promotionalCode: String
    errataAccepted: Bool!
    partyMembers: [PartyMember]!
    outfit: Outfit!
    extras: [Extra]
    specialRequests: [SpecialRequest]
    productCode: Int!
  }

  input CreateBookingCancellation {
    bookingReference: String!
    productCode: String!
    cancelWholeBooking: Bool
    cancellationReasonId: Int
    pitchIds: [Int]
  }

  input CreateQuoteAmendInput {
    bookingReference: String!
    errorCode: Int!
  }

  input CommitCancel {
    bookingReference: String
    takeFinalBalance: Boolean
    transactionGuid: String
  }

  input AmendQuoteOutfit {
    bookingReference: String!
    outfit: Outfit!
  }

  input QuoteUpdateMembershipInput {
    membershipType: Int!
    productCode: Int!
  }

  type QuoteUpdate {
    addComponentResults: [AddComponentResult]
    errorCode: Int!
    quote: Quote!
    quoteId: String!
  }

  type CreateQuoteAmend {
    quote: Quote!
  }

  type SiteNightVoucherInfo {
    titleAltText: String
    titleText: String
    leftAltContent: String
    leftContent: String
    rightContent: String
    desktopImage: String
    id: ID
    mobileImage: String
    searchListingImage: String
    quantityAvailable: Int
    quantityMinimum: Int
    unitCost: Float
  }

  type SpecialRequest {
    code: String!
  }

  type Supplement {
    id: ID!
    name: String!
    type: String!
    description: String
    totalPrice: Float!
    isMandatory: Boolean!
  }

  type BasketPopUp {
    open: Boolean!
    name: String
  }

  type DepositComponent {
    deposit: Number!
    name: String!
  }

  type DepositComponentSummaryPopUp {
    open: Boolean!
    components: [DepositComponent]
  }

  type LoginPopUp {
    open: Boolean!
    title: String
    prompt: String
    redirect: String
    showMembership: Boolean
  }

  type UpSellPopUp {
    open: Boolean!
    saving: Float
  }

  type RemovePackagePopUp {
    open: Boolean!
    componentId: String
  }

  type RemoveVouchersPopUp {
    open: Boolean!
    componentId: String
  }

  type SiteNightVoucherPopUp {
    open: Boolean!
    minimum: Int
  }

  type NoticePopUp {
    open: Boolean!
    type: String
  }

  type FormPopUp {
    open: Boolean!
    type: String
  }

  type PopUp {
    basketPopUp: BasketPopUp
    siteNightVoucherPopUp: SiteNightVoucherPopUp
    becomeAMemberPopUp, Boolean!
    bookingOverlapDialog: Boolean!
    voucherDialog: Boolean!
    loginPopUp: LoginPopUp
    upSellPopUp: UpSellPopUp
    removePackagePopUp: RemovePackagePopUp
    removeVouchersPopUp: RemoveVouchersPopUp
    noticePopUp: NoticePopUp
    formPopUp: FormPopUp
  }

  type Outfit {
    externalId: ID
    towHeight: Int
    towLength: Int
    towMake: String
    towModel: String
    towType: Int!
    vehicleHeight: Int
    vehicleLength: Int
    vehicleMake: String
    vehicleModel: String
    vehicleReg: String
    vehicleType: Int!
  }

  type PartyMember {
    age: Int
    comment: String
    dateOfBirth: String
    gender: String
    isLead: Boolean
    personId: ID!
    personName: PersonName
    telephones: [String]
    type: String
  }

  type PersonName {
    firstName: String!
    prefix: String!
    surname: String!
  }

  type PhoneNumber {
    countryPrefix: Int
    number: Int
  }

  type TelephoneNumber {
    countryPrefix: String
    number: String
  }

  type User {
    membershipNumber: Int
    membershipStartDate: String
    membershipEndDate: String
    type: String
    name: String
    firstName: String
    lastName: String
    dateOfBirth: String
    address: Address
    email: String
    telephones: [TelephoneNumber]
    vehicleOutfits: Outfit
  }

  type UserOutfits {
    outfits: [Outfit]
  }

  type UserStatus {
    loggedIn: Boolean!
  }

  input UserStatusInput {
    loggedIn: Boolean!
  }

  type SearchBoundaries {
    topLeftLat: Float!
    topLefttLng: Float!
    bottomRightLat: Float!
    bottimRightLng: Float!
  }

  type Mutation {
    createQuote(input: CreateQuoteInput): QuoteUpdate!
    createQuoteAmend(input: CreateQuoteAmendInput): CreateQuoteAmend!
    updateCurrentCampsite(
      id: String!,
      name: String,
      lat: Float,
      lng: Float,
      zoomLevel: Float,
      zoomTo: Boolean
    ): CurrentCampsite
    deleteQuoteComponent(ids: [ID]!): QuoteResponse!
    updateComparisonCampsites(params: [ComparisonCampsite]): [ComparisonCampsite]
    updateCompareModuleState(active: Bool!): compareModuleState
    updateConfirmationModalState(active: Bool!): confirmationModalState
    updateCachedCrossings(input: SearchCrossings): SearchCrossings!
    updateQuote(input: QuoteUpdateInput): QuoteResponse!
    updateQuoteMembershipDetails(input: UpdateQuoteMembershipDetailsInput): UpdateQuoteMembershipDetails
    updateQuoteAdditional(input: UpdateQuoteAdditionalInput): UpdateQuoteAdditional!
    updateQuoteExtras(input: QuoteUpdateExtrasInput): Quote!
    updateCheckoutSummary(input: CheckoutSummaryInput): CheckoutSummary
    updateUserStatus(loggedIn: Boolean): UserStatus
    createPayment(input: PaymentInput): Payment
    searchCrossing(input: SearchCrossingInput): SearchCrossings
  }

  type Query {
    availability: Availability
    availabilityDetails: AvailabilityDetails
    availabilityTotal: Availability
    campsite: Campsite
    campsiteListing: CampsiteListing
    compareModuleState: CompareModuleState
    comparisonCampsites: [ComparisonCampsite]
    confirmationModalState: ConfirmationModalState
    currentCampsite: [CurrentCampsite]
    dictionary: [DictionaryItem]
    dictionaryItem: DictionaryItem
    popUp: PopUp!
    pitches: [Pitch]
    prices: [Price]
    quote: Quote
    user: User
    userStatus: UserStatus
    userOutfits: [Outfit]
    groupedCrossings: [GroupedCrossing]
  }
`;
