import gql from 'graphql-tag';
import { stringify } from 'query-string';

import { parseDates } from './getPrices';

export function campsitePriceOptions({
  campsiteId,
  router,
}) {
  function pathBuilder({ args }) {
    return `campsite/prices?${stringify(args)}`;
  }

  const URLQuery = router.query || {};

  return ({
    variables: {
      pathBuilder,
      campsiteId,
      ...parseDates(URLQuery),
    },
    fetchPolicy: 'network-only',
  });
}

export default gql`
  query CampsitePrice(
    $campsiteId: String!,
    $end: String,
    $start: String,
    $pitchId: String,
  ) {
    campsitePrice (
      campsiteId: $campsiteId,
      end: $end,
      start: $start,
      pitchId: $pitchId,
    ) @rest(
      type: "CampsitePriceById",
      path: "campsite/prices?campsiteId={args.campsiteId}&start={args.start}&end={args.end}&pitchId={args.pitchId}"
    ) {
      data
    }
  }
`;
