const UpdateQuoteMembershipResultType = {
  // Membership Details submitted, Direct Debit details are
  // valid and member created with Gateway Api
  // [EnumMember(Value = "Membership By DD - Success")]
  MembershipByDDSuccess: 0,

  // Membership Details submitted, Direct Debit details are invalid
  // [EnumMember(Value = "Membership By DD - Validation Failed")]
  MembershipByDDValidationFailed: 1,

  // Membership Details submitted however Gateway Api server error occurred
  // [EnumMember(Value = "Membership By DD - Gateway Api Server Error")]
  MembershipByDDGatewayApiServerError: 2,

  // Membership Details submitted but member not create with Gateway Api
  // [EnumMember(Value = "Membership By CC")]
  MembershipByCC: 3,

  // Membership Details submitted and member created with Gateway Api successfully
  // [EnumMember(Value = "Membership By CC - Success")]
  MembershipByCCSuccess: 4,

  // Membership Details submitted however Gateway Api server error occurred
  // [EnumMember(Value = "Membership By CC - Gateway Api Server Error")]
  MembershipByCCGatewayApiServerError: 5,
};

export const MembershipCreationErrors = [
  UpdateQuoteMembershipResultType.MembershipByCCGatewayApiServerError,
  UpdateQuoteMembershipResultType.MembershipByDDValidationFailed,
  UpdateQuoteMembershipResultType.MembershipByDDGatewayApiServerError,
];

export default UpdateQuoteMembershipResultType;
