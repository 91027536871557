export default null;

export const StorageType = {
  SESSION_STORAGE: 'sessionStorage',
  LOCAL_STORAGE: 'localStorage',
};

/*
  Checks for the presence of a key in localStorage

  @param    key         string  The storage key to look for
  @param    storageType string  The desired storage

  @returns  found       boolean
*/
export const hasGenericStorageItem = (key, storageType = StorageType.SESSION_STORAGE) => {
  if (typeof window === 'undefined') return false;
  const storage = window[storageType];
  const storedValue = storage.getItem(key);
  // !== undefined so we return falsy values
  return storedValue !== null;
};

/*
  Simple setter for either session or local storage

  @param    key         string  The storage key to place the value in
  @param    value       string  Value to store
  @param    storageType string  The desired storage

  @returns  success     boolean
*/
export const setGenericStorageItem = (key, value, storageType = StorageType.SESSION_STORAGE) => {
  if (typeof window === 'undefined') return false;
  try {
    const storage = window[storageType];
    storage.setItem(key, value);
  } catch (error) {
    return false;
  }
  return true;
};

/*
  Simple getter for either session or local storage

  @param    key         string  The storage key for the requested value
  @param    storageType string  The desired storage

  @returns              primitive
*/
export const getGenericStorageItem = (key, storageType = StorageType.SESSION_STORAGE) => {
  if (typeof window === 'undefined') return null;
  if (!hasGenericStorageItem(key)) return null;
  try {
    const storage = window[storageType];
    return storage.getItem(key);
  } catch (error) {
    return null;
  }
};

/*
  Simple handler for deleting keys from storage

  @param    key         string  The storage key for the requested value
  @param    storageType string  The desired storage

  @returns  success     boolean
*/
export const removeGenericStorageItem = (key, storageType = StorageType.SESSION_STORAGE) => {
  if (typeof window === 'undefined') return null;
  try {
    const storage = window[storageType];
    return storage.removeItem(key);
  } catch (error) {
    return null;
  }
};

export const hasStorageItemList = (name, key, storageType = StorageType.SESSION_STORAGE) => {
  if (typeof window === 'undefined') return undefined;

  const data = window[storageType].getItem(name);
  if (!data) {
    return false;
  }
  const parsedData = JSON.parse(data);
  const item = parsedData.find(i => i.key === key);

  return !!item;
};

export const getStorageItemList = (name, key, storageType = StorageType.SESSION_STORAGE) => {
  if (typeof window === 'undefined') return undefined;

  let data = window[storageType].getItem(name);
  if (!data) {
    data = [];
  }
  const parsedData = typeof data === 'string' ? JSON.parse(data) : data;
  const item = parsedData.find(i => i.key === key);

  return item?.value;
};

export const setStorageItemList = (name, key, value, storageType = StorageType.SESSION_STORAGE) => {
  if (typeof window === 'undefined') return;

  if (!name || !key) {
    throw Error(`Storage SetItem Error, no name or key passed to method, name:${name} key:${key}`);
  }

  const data = window[storageType].getItem(name);
  let parsedData;

  if (!data) {
    parsedData = [];
  } else {
    parsedData = JSON.parse(data);
  }

  if (hasStorageItemList(name, key)) {
    const item = parsedData.find(i => i.key === key);
    item.value = value;
  } else {
    parsedData.push({
      key,
      value,
    });
  }

  window[storageType].setItem(name, JSON.stringify(parsedData));
};
