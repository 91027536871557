/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider, getDataFromTree } from 'react-apollo';
import Head from 'next/head';

import initApollo from './initApollo';

// Gets the display name of a JSX component for dev tools
function getComponentDisplayName(Component) {
  return Component.displayName || Component.name || 'Unknown'
}

export default Page => {
  class WithData extends React.Component {
    static async getInitialProps(ctx) {
      // Initial serverState with apollo (empty)
      let serverState = {
        apollo: {
          data: {}
        }
      }

      // Evaluate the composed component's getInitialProps()
      let composedInitialProps = {}
      if (Page.getInitialProps) {
        composedInitialProps = await Page.getInitialProps(ctx)
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const apollo = await initApollo();

      try {
        // Run all GraphQL queries
        await getDataFromTree(
          <ApolloProvider client={apollo}>
            <Page {...composedInitialProps} />
          </ApolloProvider>,
          {
            router: {
              asPath: ctx.asPath,
              pathname: ctx.pathname,
              query: ctx.query
            }
          }
        )
      } catch (error) {
        // Prevent Apollo Client GraphQL errors from crashing SSR.
        // Handle them in components via the data.error prop:
        // http://dev.apollodata.com/react/api-queries.html#graphql-query-data-error
      }

      // Extract query data from the Apollo store
      serverState = {
        apollo: {
          data: apollo.cache.extract()
        }
      }

      return {
        serverState,
        ...composedInitialProps
      }
    }

    constructor(props) {
      super(props);

      this.state = {
        restored: false
      }
    }

    async componentDidMount() {
      if (!this.props.serverState) return null;
      const apollo = await initApollo(this.props.serverState.apollo.data);
      this.setState({ apollo, restored: true });
    }

    render() {
      return this.state.restored === true ? (
        <ApolloProvider client={this.state.apollo}>
          <Page {...this.props} {...this.state} />
        </ApolloProvider>
      ) : null;
    }
  }

  WithData.displayName = `WithData(${getComponentDisplayName(Page)})`;

  WithData.propTypes = {
    serverState: PropTypes.shape({}),
  };

  WithData.defaultProps = {
    serverState: null,
  };

  return WithData;
}
