import gql from 'graphql-tag';

export default gql`
  query configuration {
    configuration @rest(
      type: "Configuration",
      path: "configuration"
    ) {
      amendAndCancelExtraRestrictions
      availabilityMaxDate
      basketStates
      bookingAmendmentRestrictionTypes
      bookingConfirmationImage
      bookingTimelines
      campaignCodes
      campingMiniImage
      campsiteTypes
      cancellationReasons
      configurableValues {
        emailConfirmationModalTimeToCloseMs
        minLocationSearchRadiusMilesDecimal
        showALongWayWhenGreaterThanDistanceDecimal
        showOneDecimalPlaceWhenLessThanDistanceDecimal
        campingUpsellIsEnabled
      }
      defaultCampsiteLogoImage
      defaultCampsiteZoomLevel
      defaultCrossingPreferredTime
      defaultCrossingZoomLevel
      defaultMaxCrossingAccomQuantity
      defaultMaxVehicleOverallHeight
      defaultMaxVehicleOverallHeightCrossing
      defaultMaxVehicleOverallLength
      defaultMaxVehicleOverallLengthCrossing
      disabledFilterableFeatures
      errorCodeTypes
      escortedToursDurationTypes
      excludeGeoLocationTypes
      extrasInfo
      fareTypes
      featureTypes
      features
      geoLocationPermissionsLink
      homePageLink
      internationalPhoneAccessCodes
      itxPackageRules
      joinNowLink
      liveChat
      loginUrlLink
      mapConfiguration
      membershipRenewalLink
      membershipContent
      membershipTypes
      nearbyFilterableFeatures
      onSiteFilterableFeatures
      overlappingSitesTypes
      overseasSitesTabDefaultSearchSortOrderTypes
      overseasSitesTabDefaultSearchPlaceTypes
      overseasTravelInsuranceImageUrl
      partyMemberTypes
      pitchTypesLink
      pointsOfInterestFilterableFeatures
      portZoneTypes
      ports
      preBookingCheckIssueTypes
      products
      quoteChangeTypes
      quoteComponentTypes
      quoteMembershipTypes
      redPennantInsuranceGetAQuoteLink
      routes
      siteNightVoucherInfo {
        leftAltContent
        leftContent
        extraName
        desktopImage
        id
        mobileImage
        quantityMaximum
        quantityMinimum
        rightContent
        searchListingImage
        titleAltText
        titleText
        unitCost
      }
      sortOrderTypes
      specialRequests
      suggestedUkItineraryCategories
      suppliers
      termsAndConditionsLink
      towTypes
      ukSitesTabDefaultSearchSortOrderTypes
      ukSitesTabDefaultSearchPlaceTypes
      vehicleTypes
    }
  }
`;
