import { ids as CAMPSITE_TYPE_IDS } from './campsiteTypes';

import ToursEvent from '../static/images/icons/ToursEvent.svg';
import CampingEvent from '../static/images/icons/CampingEvent.svg';
import GlampingEvent from '../static/images/icons/GlampingEvent.svg';

export const SINGLE_EVENT_TYPE = {
  id: 1,
};

const PLACE_EVENT_TYPES = {
  TOURING: {
    id: 2,
    title: 'Touring',
    icon: ToursEvent,
    largeIcon: true,
    enabled: true,
  },
  CAMPING: {
    id: 3,
    title: 'Camping',
    icon: CampingEvent,
    enabled: true,
  },
  GLAMPING: {
    id: 4,
    title: 'Glamping',
    icon: GlampingEvent,
    enabled: false, // removed glamping until ready
  },
};

export const HIDE_FROM_CAMPING_TYPES = [
  CAMPSITE_TYPE_IDS.OVERSEAS_SITE,
  CAMPSITE_TYPE_IDS.CERTIFICATED_LOCATIONS,
];

export default PLACE_EVENT_TYPES;
