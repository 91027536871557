export default {
  bookingCancel: '/booking-cancel',
  bookingCancelConfirmation: '/booking-cancel-confirmation',
  bookingCheckout: '/booking-checkout',
  checkout: '/checkout',
  confirmation: '/confirmation',
  confirmationError: '/confirmation-error',
  crossings: '/crossings',
  login: '/login',
  myBookings: '/my-bookings',
  myBookingsPrevious: '/my-bookings/previous',
  myBookingsUpcoming: '/my-bookings/upcoming',
  sites: '/sites',
  suspendedMember: '/suspended-member',
  widgets: '/widgets',
};
