/* eslint-disable */

export default (cname) => {
  if (!process.browser) return '';

  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(window.document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length).match(/^s:(.*)\..*$/)[1];
    }
  }

  return '';
};

export function getCookie(cname) {
  if (!process.browser) return '';

  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(window.document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

