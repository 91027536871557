import gql from 'graphql-tag';

export default gql`
  query ComparisonCampsites {
    comparisonCampsites @client {
      campsites {
        id
        address {
          addressLines
          country
          postCode
        },
        features {
          id
          name
          tags
          value
          iconUrl
        }
        name
        numberOfReviews
        rating
        type
        arrive
        depart
        selectedEvent {
          id
          features {
            id
            name
            tags
            value
            iconUrl
          }
          openDates {
            fromDate
            toDate
          }
          eventType
          latestArrival
          maxOutfitLength
          cheapestMemberPrice
          cheapestStandardPrice
        }
        memberPrice
        standardPrice
      }
    }
  }
`;
