export default () => 20;

// const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

// // Safari and IE support
// if (!connection) {
//   const { screen } = window;
//   if (screen) return screen.width > theme.TABLET ? 50 : 25;
//   return window.outerWidth > theme.TABLET ? 50 : 25;
// }

// const type = connection.effectiveType
//   ? connection.effectiveType.toLowerCase()
//   : connection.type.toLowerCase();

// if (type === '2g' || type === '3g' || type === 'cellular') {
//   return 25;
// }

// return 50;
