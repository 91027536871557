import { cloneDeep, uniqBy } from 'lodash';

import GET_CACHED_CROSSINGS from '../components/SearchForm/graphql/getCachedCrossings';

function rearrangeObjectLayout(crossings = []) {
  // create array of object with unique ids
  const array = crossings !== null ?
    uniqBy(
      crossings.map(crossing => ({ id: crossing.relatedFareTypeId })),
      item => item.id,
    ) :
    [];

  /* eslint-disable no-underscore-dangle */
  const groupedCrossings = array.map((groupedCrossing) => {
    const groupedCrossingRef = { ...groupedCrossing };
    // add crossing by id as "crossings" property, to the object with is
    groupedCrossingRef.__typename = 'GroupedCrossing';
    groupedCrossingRef.crossings =
      crossings.filter(
        dataCrossing => dataCrossing.relatedFareTypeId === groupedCrossing.id,
      );

    groupedCrossingRef.crossings.forEach((crossing) => {
      const crossingRef = crossing;
      crossingRef.__typename = 'Crossings';

      // Typepatching outboundItinerary
      crossingRef.outboundItinerary.__typename = 'OutboundItinerary';
      crossingRef.outboundItinerary.timeTable.__typename = 'TimeTable';

      crossingRef.outboundItinerary.accommodation.forEach((accomodation) => {
        const accomodationRef = accomodation;
        accomodationRef.__typename = 'Accommodation';
      });

      if (!crossingRef.outboundItinerary.accommodation.length) {
        crossingRef.outboundItinerary.accommodation.push({
          __typename: 'Accommodation',
          id: '',
        });
      }

      crossingRef.outboundItinerary.supplements.forEach((supplement) => {
        const supplementRef = supplement;
        supplementRef.__typename = 'Supplement';
      });

      if (!crossingRef.outboundItinerary.supplements.length) {
        crossingRef.inboundItinerary.supplements.push({
          __typename: 'Supplement',
          id: '',
          quantity: null,
        });
      }

      // Typepatching inboundItinerary
      if (crossingRef.inboundItinerary) {
        crossingRef.inboundItinerary.__typename = 'InboundItinerary';
        crossingRef.inboundItinerary.timeTable.__typename = 'TimeTable';

        crossingRef.inboundItinerary.accommodation.forEach((accomodation) => {
          const accomodationRef = accomodation;
          accomodationRef.__typename = 'Accommodation';
        });

        if (!crossingRef.inboundItinerary.accommodation.length) {
          crossingRef.inboundItinerary.accommodation.push({
            __typename: 'Accommodation',
            id: '',
          });
        }

        crossingRef.inboundItinerary.supplements.forEach((supplement) => {
          const supplementRef = supplement;
          supplementRef.__typename = 'Supplement';
        });

        if (!crossingRef.inboundItinerary.supplements.length) {
          crossingRef.inboundItinerary.supplements.push({
            __typename: 'Supplement',
            id: '',
            quantity: null,
          });
        }
      } else {
        crossingRef.inboundItinerary = {
          __typename: 'InboundItinerary',
          accommodation: [
            {
              __typename: 'Accomodation',
              id: '',
            },
          ],
          timeTable: {
            __typename: 'TimeTable',
            fromDate: '',
            toDate: '',
          },
          supplements: [
            {
              __typename: 'Supplements',
              id: '',
              name: '',
              quantity: null,
            },
          ],
        };
      }
    });

    return groupedCrossingRef;
  });
  /* eslint-enable */

  return groupedCrossings;
}

export function searchCrossings(cache, response) {
  // Deep clone to prevent mutating response
  const results = cloneDeep(response.data.results);

  const previous = cache.readQuery({ query: GET_CACHED_CROSSINGS });

  // Adds default values for cache
  const groupedCrossings = rearrangeObjectLayout(results.crossings);

  const data = {
    groupedCrossings: uniqBy(
      previous.groupedCrossings.concat(groupedCrossings),
      item => item.id,
    ),
  };

  cache.writeData({ data });

  return null;
}

const resolver = {
  defaults: {
    groupedCrossings: [
      {
        __typename: 'GroupedCrossing',
        id: null,
        crossings: [
          {
            __typename: 'Crossings',
            charges: {
              currencyCode: '',
              totalAmount: null,
              __typename: 'Charges',
            },
            id: null,
            supplierCode: '',
            relatedFareTypeId: null,
            fareType: null,
            outboundItinerary: {
              __typename: 'OutboundItinerary',
              accommodation: [
                {
                  __typename: 'Accomodation',
                  id: '',
                },
              ],
              timeTable: {
                __typename: 'TimeTable',
                fromDate: '',
                toDate: '',
              },
              supplements: [
                {
                  __typename: 'Supplements',
                  id: '',
                  name: '',
                  quantity: null,
                },
              ],
            },
            inboundItinerary: {
              __typename: 'InboundItinerary',
              accommodation: [
                {
                  __typename: 'Accomodation',
                  id: '',
                },
              ],
              timeTable: {
                __typename: 'TimeTable',
                fromDate: '',
                toDate: '',
              },
              supplements: [
                {
                  __typename: 'Supplements',
                  id: '',
                  name: '',
                  quantity: null,
                },
              ],
            },
          },
        ],
      },
    ],
  },
};

export const { defaults } = resolver;

export default resolver;
