import { merge } from 'lodash';

import GET_QUOTE from '../components/Quote/graphql/getQuote';

export function updateQuoteCache(cache, { data: { quoteResponse } }) {
  const previous = cache.readQuery({ query: GET_QUOTE });
  const data = { quote: merge(previous.quote, quoteResponse.quote) };

  data.quote.campsiteBookings = quoteResponse.quote.campsiteBookings;
  data.quote.crossingBookings = quoteResponse.quote.crossingBookings;
  data.quote.extras = quoteResponse.quote.extras;

  /**
   * `data` must contain all properties expected by the query to
   * successfully write to the cache.
   */
  try {
    cache.writeQuery({ query: GET_QUOTE, data });
  } catch (error) {
    console.error('Error saving quote to cache', error);
  }
  return data;
}

const resolver = {
  defaults: {
    status: null,
    product: null,
    totalPrice: null,
    deposit: null,
    balanceDueDate: null,
    departureDate: null,
    cancellationPenalty: null,
    cancellationPenaltyApplicableFrom: null,
    partyMembers: [],
    outfit: null,
    campsiteBookings: [],
    crossingBookings: [],
    extras: [],
    quoteId: null,
    errorCode: null,
  },
};

export const { defaults } = resolver;

export default resolver;
