import UpdateQuoteMembershipResultType from '../constants/UpdateQuoteMembershipResultType';

const quoteMembershipTypes = {
  Unknown: 'Unknown',
  LoggedInMember: 'Logged In Member',
  NotLoggedInOrNonMember: 'Not Logged In Or Non Member',
  MembershipByDD: 'Membership By DD',
  MembershipByCC: 'Membership By CC',
};

const getItemIndexByValue = (obj, value) => Object.values(obj).indexOf(value);

export const newMemberTypes = [
  getItemIndexByValue(quoteMembershipTypes, quoteMembershipTypes.MembershipByDD),
  getItemIndexByValue(quoteMembershipTypes, quoteMembershipTypes.MembershipByCC),
];

export const loggedInMemberTypes = [
  getItemIndexByValue(quoteMembershipTypes, quoteMembershipTypes.LoggedInMember),
  ...newMemberTypes,
];

/**
 * Gets the index used by the API
 *
 * @param {string} value the value of a quoteMembershipTypes property,
 * e.g. quoteMembershipTypes.LoggedInMember
 * @returns {number} index of quoteMembershipTypes
 */
export const getENumWithValue = (value) => getItemIndexByValue(quoteMembershipTypes, value);

export const isMembershipInBasket = (quote) => (
  quote?.membershipType === getENumWithValue(quoteMembershipTypes.MembershipByCC)
    || quote?.membershipType === getENumWithValue(quoteMembershipTypes.MembershipByDD)
);

// hide basket line item if it's added to quote.membership
export const isMembershipShownInBasket = (quote) => isMembershipInBasket(quote) &&
  !quote?.membership;

export const isMembershipByDD = (quote) => (
  quote?.membershipType === getENumWithValue(quoteMembershipTypes.MembershipByDD)
);

export const getMembershipPrice = (quote, configuration, isJoint = false) => {
  const priceData = isMembershipByDD(quote)
    ? configuration?.membershipTypes?.directDebit
    : configuration?.membershipTypes?.creditCard;
  if (priceData) {
    return isJoint ? priceData.jointCost : priceData.costSingle;
  }

  return undefined;
};

export const getJoiningFee = ({ membershipTypes }) => membershipTypes?.joiningFee;

export const getQuoteMembershipDetails = (quote, configuration) => {
  const showMembershipLineItem = isMembershipShownInBasket(quote);
  const membershipAlreadyInQuote = !!quote.membership;
  const numberOfFamilyMembers = quote.membership?.familyMembers?.length || 0;
  const {
    membershipTypes: {
      additionalFamilyMemberCost,
    },
  } = configuration;

  if (showMembershipLineItem || membershipAlreadyInQuote) {
    return {
      isDirectDebit: membershipAlreadyInQuote
        ? quote.membership.resultType < UpdateQuoteMembershipResultType.MembershipByCC
        : isMembershipByDD(quote),
      price: getMembershipPrice(quote, configuration),
      extras: !numberOfFamilyMembers ? undefined : [{
        label: 'Family Members',
        price: additionalFamilyMemberCost * numberOfFamilyMembers,
      }],
      numberOfFamilyMembers,
      joiningFee: getJoiningFee(configuration),
      hasPaid: quote.membership?.resultType
        === UpdateQuoteMembershipResultType.MembershipByDDSuccess
        || quote.membership?.resultType
        === UpdateQuoteMembershipResultType.MembershipByDDGatewayApiServerError,
      hasJointMember: !!quote.membership?.joint,
    };
  }

  return undefined;
};

export default quoteMembershipTypes;
