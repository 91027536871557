import gql from 'graphql-tag';

export default gql`
  query SearchCrossings {
    groupedCrossings @client {
      id
      crossings {
        charges {
          currencyCode
          totalAmount
        }
        id
        supplierCode
        relatedFareTypeId
        fareType
        outboundItinerary {
          accommodation {
            id
          }
          timeTable {
            fromDate
            toDate
          }
          supplements {
            id
            quantity
          }
        }
        inboundItinerary {
          accommodation {
            id
          }
          timeTable {
            fromDate
            toDate
          }
          supplements {
            id
            quantity
          }
        }
      }
    }
  }
`;
