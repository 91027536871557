import getConfig from 'next/config';
import fetch from 'isomorphic-fetch';

export const AuditType = {
  NotSet: 0,
  Debug: 1,
  Information: 2,
  Error: 3,
  RequestReceived: 4,
  ResponseSent: 5,
  RequestSent: 6,
  ResponseReceived: 7,
};

export function safelyToString(item) {
  const keyBlackList = ['operation', 'forward']; // Apollo query internals not required
  let str = '';
  if (typeof item === 'string') {
    str = item;
  }

  if (typeof item === 'boolean' || typeof item === 'number') {
    str = item.toString();
  }

  if (typeof item === 'object') {
    const props = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in item) {
      if (key && !keyBlackList.includes(key)) {
        const value = item[key];
        props.push(`${key}: ${safelyToString(value)}`);
      }
    }
    if (props.length === 0) {
      if (item.message) {
        props.push(`message: ${safelyToString(item.message)}`);
      }

      if (item.stack) {
        props.push(`stack: ${safelyToString(item.stack)}`);
      }

      props.push(`toString: ${safelyToString(item.toString())}`);
    }

    str += `{ ${props.join(', ')} }`;
  }

  return str;
}

export default function logToCasClientSide(auditType, controller = '', action = '', error = null) {
  if (typeof window === 'undefined') return; // client side only
  const { publicRuntimeConfig } = getConfig();
  const { ENVIRONMENT } = publicRuntimeConfig;
  //  check if we have verbose logging enabled in octopus, if not, only log errors
  if (!ENVIRONMENT.VerboseFELogging && auditType !== AuditType.Error) return;

  let body = '';
  try {
    body = JSON.stringify(error, Object.getOwnPropertyNames(error));
  } catch (e) {
    body = 'Problem parsing error';
  }

  const url = `${ENVIRONMENT?.IBEUrl}/api/Logger/PostLogItem`;
  const data = JSON.stringify({
    auditType,
    controller: `UI - ${controller}`,
    action,
    body,
  });

  fetch(url, {
    method: 'POST',
    headers: {
      'Cache-Control': 'no-store',
      'Content-Type': 'application/json',
    },
    body: data,
  }).catch((e) => {
    console.error(e.message);
  });
}
